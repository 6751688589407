import { IInfoMediaStepBase, IStepCore } from '../steps/Base';
import { StepTypes } from '../StepTypes.enum';

export const MEDIA_STEPS = [
  // INFO
  StepTypes.INFO_AUDIO,
  StepTypes.INFO_PDF,
  StepTypes.INFO_PHOTO,
  StepTypes.INFO_VIDEO,
  StepTypes.LAYOUT_ASSEMBLY_PICTURE,
  StepTypes.LAYOUT_ASSEMBLY_VIDEO,
  StepTypes.LAYOUT_ASSEMBLY_DETAIL,
  StepTypes.LAYOUT_SCREW_FITTING,
] as const;

export type MediaSteps = (typeof MEDIA_STEPS)[number];

export const hasMedia = (step: IStepCore): step is IInfoMediaStepBase => MEDIA_STEPS.includes(step.type as MediaSteps);
