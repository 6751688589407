import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { shouldDisplayLinks } from '@workerbase/domain/user';
import { FunctionType } from '@workerbase/api/http/function';
import { PaginationMeta } from '@workerbase/types/Response';
import { MqttTopics } from '@workerbase/types/MQTT/MqttTopics';

import { updateListConfigSaga } from '@redux/common/ListConfig/sagas';
import { mqttSubscribeWithCallback, mqttUnsubscribeFromCallback, mqttUpdateCallback } from '@redux/Mqtt';
import { getFunctionTestRunData } from '@redux/Users/actions';
import {
  createFunction,
  deleteFunctionById,
  deployFunctionWithId,
  getFunctionById,
  getFunctions,
  invokeFunctionWithId,
  updateFunctionById,
} from 'services/networking/functions';
import { convertFunctionToFunctionPOST, convertFunctionToFunctionPUT } from 'services/normalizers/functions';
import { handleRequestError } from '../common';
import {
  CreateFunctionRequestAction,
  deleteFunctionByIdError,
  deleteFunctionByIdSuccess,
  deployFunctionFailure,
  deployFunctionSuccess,
  FunctionsActions,
  getFunctionByIdError,
  getFunctionByIdSuccess,
  getFunctionsError,
  getFunctionsSuccess,
  UpdateFunctionByIdRequestAction,
} from './actions';
import { getListConfigs } from './selectors';

export function* getFunctionsRequestSaga(action) {
  try {
    const listConfigs = yield select(getListConfigs);
    const projectId = action.payload.projectId;

    const response = yield call(getFunctions, {
      projectId,
      page: listConfigs.pagination.currentPage,
      perPage: listConfigs.pagination.itemsPerPage,
      sorting: listConfigs.sorting,
      filtering: listConfigs.filtering.searchTerms,
      aggregateLinks: shouldDisplayLinks(listConfigs),
    });

    const functions: FunctionType[] = response.data;
    const meta: PaginationMeta = response.meta;

    yield put(getFunctionsSuccess(functions, meta));
  } catch (error) {
    yield put(getFunctionsError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* getFunctionByIdRequestSaga(action) {
  try {
    const func: FunctionType = yield call(getFunctionById, action.payload.functionId);
    yield put(getFunctionTestRunData(func.id));
    yield put(getFunctionByIdSuccess(func));
  } catch (error) {
    yield put(getFunctionByIdError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* createFunctionRequestSaga(action: CreateFunctionRequestAction) {
  try {
    const func: FunctionType = yield call(createFunction, convertFunctionToFunctionPOST(action.payload.functionData));
    yield put(getFunctionTestRunData(func.id));
    yield put(getFunctionByIdSuccess(func));
    yield put(push(`/projects/${func.projectId}/functions/${func.id}/edit`));
    yield call(toast.success, 'Function created');
  } catch (error) {
    yield put(getFunctionByIdError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* deleteFunctionByIdRequestSaga(action) {
  try {
    yield call(deleteFunctionById, action.payload.functionId);
    yield call(toast.success, 'Function deleted');
    yield put(deleteFunctionByIdSuccess(action.payload.functionId));
  } catch (error) {
    yield put(deleteFunctionByIdError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* deployFunctionSaga(action) {
  try {
    yield call(deployFunctionWithId, action.payload.functionId);
    yield put(deployFunctionSuccess(action.payload.functionId));
    yield call(toast.success, 'Function deployed successfully');
  } catch (error) {
    yield put(deployFunctionFailure((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* invokeFunctionSaga(action) {
  try {
    const payload = JSON.parse(action.payload.payload);
    try {
      yield call(invokeFunctionWithId, action.payload.functionId, payload);
      yield call(toast.success, 'Function run successful');
    } catch (invokeError) {
      yield call(toast.error, `Function failed: ${(invokeError as Error)?.message || ''}`);
    }
  } catch (parseError) {
    yield call(toast.error, `Can't run function. Test run data JSON is invalid`);
  }
}

export function* subscribeToMqttSaga(action) {
  try {
    yield put(
      mqttSubscribeWithCallback(
        MqttTopics.FUNCTION_LOGS.replace('+', action.payload.functionId),
        action.payload.callback,
        FunctionsActions.MQTT_SUBSCRIBE_REALTIME_LOGS,
      ),
    );
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* updateMqttCallbackSaga(action) {
  try {
    yield put(
      mqttUpdateCallback(
        MqttTopics.FUNCTION_LOGS.replace('+', action.payload.functionId),
        action.payload.callback,
        FunctionsActions.MQTT_SUBSCRIBE_REALTIME_LOGS,
      ),
    );
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* unsubscribeToMqttSaga(action) {
  try {
    yield put(
      mqttUnsubscribeFromCallback(
        MqttTopics.FUNCTION_LOGS.replace('+', action.payload.functionId),
        action.payload.callback,
        FunctionsActions.MQTT_SUBSCRIBE_REALTIME_LOGS,
      ),
    );
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* updateFunctionByIdRequestSaga(action: UpdateFunctionByIdRequestAction) {
  try {
    const func: FunctionType = yield call(
      updateFunctionById,
      action.payload.functionId,
      convertFunctionToFunctionPUT(action.payload.functionData),
    );
    yield put(getFunctionByIdSuccess(func));
    yield call(toast.success, 'Function updated');
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export default function* functionsSagas() {
  yield takeLatest(FunctionsActions.GET_FUNCTIONS_REQUEST, getFunctionsRequestSaga);
  yield takeLatest(FunctionsActions.GET_FUNCTION_BY_ID_REQUEST, getFunctionByIdRequestSaga);
  yield takeLatest(FunctionsActions.CREATE_FUNCTION_REQUEST, createFunctionRequestSaga);
  yield takeLatest(FunctionsActions.DELETE_FUNCTION_BY_ID_REQUEST, deleteFunctionByIdRequestSaga);
  yield takeLatest(FunctionsActions.UPDATE_FUNCTION_BY_ID_REQUEST, updateFunctionByIdRequestSaga);
  yield takeLatest(FunctionsActions.DEPLOY_FUNCTION_REQUEST, deployFunctionSaga);
  yield takeLatest(FunctionsActions.INVOKE_FUNCTION_REQUEST, invokeFunctionSaga);
  yield takeLatest(FunctionsActions.MQTT_SUBSCRIBE_REALTIME_LOGS, subscribeToMqttSaga);
  yield takeLatest(FunctionsActions.MQTT_UNSUBSCRIBE_REALTIME_LOGS, unsubscribeToMqttSaga);
  yield takeLatest(FunctionsActions.MQTT_UPDATE_REALTIME_LOGS_CALLBACK, updateMqttCallbackSaga);
  yield takeLatest(FunctionsActions.UPDATE_SORTING, updateListConfigSaga);
  yield takeLatest(FunctionsActions.UPDATE_LISTCONFIG_PROPERTIES, updateListConfigSaga);
}
