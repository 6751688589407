import { IDocumentVideoChapter } from './DocumentVideoChapter.interface';
import { IDocumentVideoStep } from './DocumentVideoStep.interface';
import { DocumentVideoViewModes } from './DocumentVideoViewModes.enum';

export interface IDocumentVideoOriginalView {
  description: string;
}

export interface IDocumentVideoSummaryView {
  description: string;
}

export interface IDocumentVideoChaptersView {
  description: string;
  chapters: IDocumentVideoChapter[];
}

export interface IDocumentVideoStepsView {
  description: string;
  requirements: string;
  steps: IDocumentVideoStep[];
}

export interface IDocumentVersionVideoData {
  [DocumentVideoViewModes.ORIGINAL]: IDocumentVideoOriginalView;
  [DocumentVideoViewModes.SUMMARY]: IDocumentVideoSummaryView;
  [DocumentVideoViewModes.CHAPTERS]: IDocumentVideoChaptersView;
  [DocumentVideoViewModes.STEPS]: IDocumentVideoStepsView;
}
