import { IPaginatedQueryParams, ApiResponsePaginated, PaginationMeta } from '@workerbase/api/http/common';
import { ReasonTypes } from '@workerbase/domain/reason';
import {
  ProjectBody,
  ProjectResponse,
  ProjectResponseWithCreatedByPopulated,
  ProjectUpdateBody,
  ProjectConfig,
  ProjectLogResponse,
} from '@workerbase/api/http/project';
import { ReasonResponse } from '@workerbase/types/api/reasons';
import { RoleResponse } from '@workerbase/api/http/role';
import { Issue } from '@workerbase/types/Error';
import { ListConfigSorting } from '@workerbase/api/http/user';
import { RuleValidationPayloadBody, TriggerResponse } from '@workerbase/api/http/rule';
import { ProjectRestoreIds } from '@workerbase/types/project/ProjectRestoreIds';
import { ApiResponse } from '@workerbase/types/Response';
import { AvailableCondition, SelectedCondition, TriggerTypes } from '@workerbase/domain/rule';

import { GraphQLListResponse, GraphQLResponseBodyError, ListArgs } from 'services/types/GraphQL';
import { ActionGET, Project, ProjectWithCreatedByPopulated } from 'services/types/Project';
import { ProjectLog } from 'services/types/ProjectLog';
import { Role } from 'services/types/Role';
import { Trigger, TriggerPayloadPOST } from 'services/types/Rule/Trigger';

import { ProjectRestorePreviewDetails } from '@workerbase/types/project/ProjectRestorePreviewDetails';
import { SelectableVariable } from '@workerbase/types/Variable';
import { AxiosResponse } from 'axios';
import { uniq } from 'lodash';
import {
  normalizeProject,
  normalizeProjectLog,
  normalizeProjectWithCreatedByPopulated,
  normalizeTrigger,
} from '../normalizers/projects';
import { normalizeRole } from '../normalizers/roles';
import { api } from './api';
import { makeGraphqlListRequest } from './graphql';

export const PROJECTS_ENDPOINT = '/api/v1/projects';
const PROJECTS_GRAPHQL_MODEL = 'projects';

const tableFieldsToGraphqlMapping: { [keu: string]: string[] } = {
  id: ['_id'],
  adminRoles: [
    'adminRoles._id',
    'adminRoles.description',
    'adminRoles.name',
    'adminRoles.apps',
    'adminRoles.deviceApps',
  ],
  roles: ['roles._id', 'roles.description', 'roles.name', 'roles.apps', 'roles.deviceApps'],
  createdAt: ['meta.createdAt'],
};

// Initial project fields for correct work of the system.
// Eg: roles and adminRoles are required for access and permission check
const BASE_PROJECT_FIELDS = [
  '_id',
  'config.taskFilter',
  'config.taskDetailsFilter',
  'roles._id',
  'adminRoles._id',
  'meta.createdBy.firstName',
  'meta.createdBy.lastName',
];

type GetProjects = (options: {
  page: number;
  perPage: number;
  fields?: string[];
  sorting?: ListConfigSorting;
  filtering?: string;
  preciseCount?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterQuery?: Record<string, any>;
}) => Promise<{
  data?: ProjectWithCreatedByPopulated[];
  projectRoles?: Role[];
  meta?: PaginationMeta;
  errors?: GraphQLResponseBodyError[];
}>;

// TODO: Consider using Promise<PaginatedResponse<T>> as return type
export const getProjects: GetProjects = async ({
  page = 1,
  perPage,
  fields,
  sorting,
  filtering,
  filterQuery,
  preciseCount = true,
}) => {
  const graphQlFields = uniq(fields || ['id', 'name']).reduce(
    (acc: string[], tableField: string) => {
      const graphqlFields = tableFieldsToGraphqlMapping[tableField];
      if (graphqlFields) {
        acc.push(...graphqlFields);
      } else {
        acc.push(tableField);
      }
      return acc;
    },
    [...BASE_PROJECT_FIELDS],
  );

  const gqlArgs: ListArgs = {
    page,
    perpage: perPage,
    sort: sorting?.selector,
    order: sorting?.sortDirection,
    textSearch: filtering,
    filter: {
      deleted: {
        EQ: false,
      },
      isSystem: { NE: true },
      ...filterQuery,
    },
  };

  const {
    data: { data, errors },
  } = await makeGraphqlListRequest<{
    projects?: GraphQLListResponse<ProjectResponseWithCreatedByPopulated>;
  }>(PROJECTS_GRAPHQL_MODEL, graphQlFields, gqlArgs, preciseCount);

  const projects = data.projects?.edges;
  const meta = data.projects?.pageInfo;

  const projectRoles = projects
    ?.map(({ roles, adminRoles }) => [...(roles || []), ...(adminRoles || [])] as RoleResponse[])
    .reduce((flattenedArray, roles: RoleResponse[]) => [...flattenedArray, ...roles], [])
    .filter(({ _id }, i, roles) => roles.findIndex((role) => role._id === _id) === i)
    .map(normalizeRole);

  return {
    data: projects?.map(normalizeProjectWithCreatedByPopulated),
    meta,
    projectRoles,
    errors,
  };
};

export const getProjectById = async (projectId: string): Promise<Project> => {
  const {
    data: { data },
  } = await api.get<ApiResponse<ProjectResponse>>(`${PROJECTS_ENDPOINT}/${projectId}`);

  return normalizeProject(data);
};

export const getReasons = async (type: ReasonTypes, projectId: string): Promise<ReasonResponse[]> => {
  const {
    data: { data },
  } = await api.get<ApiResponse<ReasonResponse[]>>(`${PROJECTS_ENDPOINT}/${projectId}/reasons`, {
    params: { type },
  });

  return data;
};

export const getRulesConditions = async (
  projectId: string,
  trigger: Trigger,
  conditions: SelectedCondition[],
  ruleId?: string,
) => {
  const triggerPayload: TriggerPayloadPOST = {
    source: trigger.source,
    type: trigger.type,
    resourceId: trigger.type === TriggerTypes.EXTERNAL_EVENT ? trigger.resourceId : undefined,
  };
  const payload = {
    trigger: triggerPayload,
    conditions,
    ruleId,
  };

  const {
    data: { data },
  } = await api.post<ApiResponse<AvailableCondition[]>>(`${PROJECTS_ENDPOINT}/${projectId}/rules/conditions`, payload);

  return data;
};

export const validateRule = async (projectId: string, payload: RuleValidationPayloadBody): Promise<Issue[]> => {
  const {
    data: { data },
  } = await api.post<ApiResponse<Issue[]>>(`${PROJECTS_ENDPOINT}/${projectId}/rules/conditions/validation`, payload);

  return data;
};

export const updateProjectById = async (projectId: string, project: ProjectUpdateBody): Promise<Project> => {
  const {
    data: { data },
  } = await api.put<{ data: ProjectResponse }>(`${PROJECTS_ENDPOINT}/${projectId}`, project);

  return normalizeProject(data);
};

export const updateProjectConfig = async (projectId: string, config: ProjectConfig): Promise<ProjectConfig> => {
  const {
    data: { data },
  } = await api.post<{ data: ProjectConfig }>(`${PROJECTS_ENDPOINT}/${projectId}/taskFields`, config);

  return data;
};

export const createProject = async (project: ProjectBody): Promise<Project> => {
  const {
    data: { data },
  } = await api.post<ApiResponse<ProjectResponse>>(PROJECTS_ENDPOINT, project);

  return normalizeProject(data);
};

export const deleteProjectById = async (projectId: string): Promise<boolean> => {
  await api.delete<ApiResponse<null>>(`${PROJECTS_ENDPOINT}/${projectId}`);

  return true;
};

export const getRuleTriggersForProjectId = async (projectId: string): Promise<Trigger[]> => {
  const {
    data: { data },
  } = await api.get<ApiResponse<TriggerResponse[]>>(`${PROJECTS_ENDPOINT}/${projectId}/rules/triggers`);
  return data.map(normalizeTrigger);
};

export const getRuleActionsForProjectId = async (
  projectId: string,
  trigger: Trigger,
  conditions: SelectedCondition[],
): Promise<ActionGET[]> => {
  const triggerPayload: TriggerPayloadPOST = {
    source: trigger.source,
    type: trigger.type,
    resourceId: trigger.type === TriggerTypes.EXTERNAL_EVENT ? trigger.resourceId : undefined,
  };

  const payload = {
    trigger: triggerPayload,
    filter: [conditions],
  };

  const {
    data: { data },
  } = await api.post<ApiResponse<ActionGET[]>>(`${PROJECTS_ENDPOINT}/${projectId}/rules/actions`, payload);

  return data;
};

export const getProjectVariablesForProjectId = async (projectId: string): Promise<SelectableVariable[]> => {
  const {
    data: { data },
  } = await api.get<{ data: SelectableVariable[] }>(`${PROJECTS_ENDPOINT}/${projectId}/variables`);
  return data.filter((variable) => variable != null);
};

export const getRuleVariablesForProjectId = async (
  projectId: string,
  trigger: Trigger,
  conditions: SelectedCondition[],
): Promise<SelectableVariable[]> => {
  const triggerPayload: TriggerPayloadPOST = {
    source: trigger.source,
    type: trigger.type,
    resourceId: trigger.type === TriggerTypes.EXTERNAL_EVENT ? trigger.resourceId : undefined,
  };

  const payload = {
    trigger: triggerPayload,
    filter: [conditions],
  };

  // TODO: set response type and double check whether try-catch is needed
  const {
    data: { data },
  } = await api.post<ApiResponse<SelectableVariable[]>>(`${PROJECTS_ENDPOINT}/${projectId}/rules/variables`, payload);

  return data ?? [];
};

export const importProjectRequest = async (file: File): Promise<void> => {
  const formData = new FormData();
  formData.append('file', file);

  await api.post<ApiResponse<null>>(`${PROJECTS_ENDPOINT}/import`, formData);
};

export const getProjectRestorePreview = async (
  projectId: string,
  file: File,
): Promise<ProjectRestorePreviewDetails> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('projectId', projectId);
  const {
    data: { data },
  } = await api.post<ApiResponse<ProjectRestorePreviewDetails>>(`${PROJECTS_ENDPOINT}/restore/preview`, formData);

  return data;
};

export const restoreProject = async (
  projectRestoreIds: ProjectRestoreIds,
  userId?: string,
  file?: File,
): Promise<AxiosResponse<ApiResponse<null>> | null> => {
  if (userId && file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('userId', userId);
    formData.append('projectRestoreIds', JSON.stringify(projectRestoreIds));
    const response = await api.post<ApiResponse<null>>(`${PROJECTS_ENDPOINT}/restore`, formData);

    return response;
  }
  return null;
};

type GetProjectLogsPaginated = (
  projectId: string,
  page?: number,
  perPage?: number,
  sorting?: ListConfigSorting,
  filtering?: string,
  selectedTabKey?: string,
  categoryKey?: string,
) => Promise<ApiResponsePaginated<ProjectLog[]>>;

export const getProjectLogsPaginated: GetProjectLogsPaginated = async (
  projectId,
  page = 1,
  perPage = 20,
  sorting,
  filtering,
  selectedTabKey,
  categoryKey,
) => {
  const params: IPaginatedQueryParams = {
    page,
    perpage: perPage,
    sort: sorting?.selector,
    order: sorting?.sortDirection,
    textSearch: filtering,
    category: selectedTabKey && categoryKey ? selectedTabKey : undefined,
    categoryKey: selectedTabKey && categoryKey ? categoryKey : undefined,
  };

  const {
    data: { data, ...rest },
  } = await api.get<ApiResponsePaginated<ProjectLogResponse[]>>(`${PROJECTS_ENDPOINT}/${projectId}/logs`, { params });

  return { data: data.map(normalizeProjectLog), ...rest };
};

export const getTasksFilterConditions = async (projectId: string) => {
  const {
    data: { data },
  } = await api.get<ApiResponse<AvailableCondition[]>>(`${PROJECTS_ENDPOINT}/${projectId}/tasks/conditions`);

  return data;
};
