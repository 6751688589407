import { DocumentTypes } from '../../document/DocumentTypes.enum';
import { MetaInterface } from '../../common';
import { ITaskTranslation } from '../ITask.interface';
import { ITaskBase } from '../ITaskBase.interface';
import { TaskStatus } from '../TaskStatus.enum';
import { TaskTypes } from '../TaskTypes.enum';
import { WbTaskActions } from '../TaskActions.enum';

export const KNOWLEDGE_CAPTURE_TASK_DEFAULT_TITLE = 'Knowledge Capture';
export const KNOWLEDGE_CAPTURE_TASK_DEFAULT_PRIORITY = 2;

export type KnowledgeCaptureTaskStatus = Exclude<TaskStatus, TaskStatus.ARCHIVED>;

export interface IKnowledgeCaptureResult {
  name: string;
  documentType: DocumentTypes;
  /** when the task is submitted, the fileId is set */
  fileId?: string;
}

export interface IKnowledgeCaptureTask extends ITaskBase<KnowledgeCaptureTaskStatus> {
  type: TaskTypes.KnowledgeCapture;
  result?: IKnowledgeCaptureResult;
  translations: ITaskTranslation[];
  meta?: MetaInterface;
}

export type KnowledgeCaptureTask = IKnowledgeCaptureTask & { _id: string; meta: MetaInterface };

export type KnowledgeCaptureTaskActions = Exclude<WbTaskActions, WbTaskActions.HANDOVER>;
