import React, { FC, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@mui/material';
import useDrivePicker from 'react-google-drive-picker';
import { GDRIVE_CLIENT_ID, GDRIVE_DEVELOPER_KEY } from '../../../globals';
import googleDriveIcon from '../../../assets/icons/google-drive.svg';

export interface GoogleDriveFile {
  id: string;
  name: string;
  type: string;
  size: number;
}

export type ViewIdOptions =
  | 'DOCS'
  | 'DOCS_IMAGES'
  | 'DOCS_IMAGES_AND_VIDEOS'
  | 'DOCS_VIDEOS'
  | 'DOCUMENTS'
  | 'DRAWINGS'
  | 'FOLDERS'
  | 'FORMS'
  | 'PDFS'
  | 'SPREADSHEETS'
  | 'PRESENTATIONS';

export interface GoogleDrivePickerProps {
  multiple?: boolean;
  viewId?: ViewIdOptions;
  onFilesSelected: (options: { files: GoogleDriveFile[]; accessToken: string }) => void;
}

export const GoogleDrivePicker: FC<GoogleDrivePickerProps> = ({
  multiple = false,
  viewId = 'PDFS',
  onFilesSelected,
}) => {
  const intl = useIntl();
  const [openPicker, authResponse] = useDrivePicker();
  const authResponseRef = useRef(authResponse);
  useEffect(() => {
    if (authResponse) {
      authResponseRef.current = authResponse;
    }
  }, [authResponse]);

  const handleOpenPicker = () => {
    openPicker({
      clientId: GDRIVE_CLIENT_ID,
      developerKey: GDRIVE_DEVELOPER_KEY,
      viewId,
      showUploadView: false,
      showUploadFolders: false,
      supportDrives: true,
      multiselect: multiple,
      token: authResponseRef.current?.access_token,
      callbackFunction: (data) => {
        if (data.action === 'cancel') {
          return;
        }
        if (data.action === 'picked') {
          if (!authResponseRef.current?.access_token) {
            throw new Error('Authentication with Google Drive failed. Access token is missing.');
          }
          onFilesSelected({
            files: data.docs.map((doc) => ({
              id: doc.id,
              name: doc.name,
              type: doc.mimeType,
              size: doc.sizeBytes,
            })),
            accessToken: authResponseRef.current?.access_token,
          });
        }
      },
    });
  };

  return (
    <Box
      sx={{
        height: '56px',
        border: 1,
        borderColor: 'divider',
        borderRadius: 1,
        width: '100%',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={handleOpenPicker}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Box
          component="img"
          sx={{
            width: '20px',
            mr: 2,
          }}
          src={googleDriveIcon}
        />
        <Typography variant="subtitle1">{intl.formatMessage({ id: 'documents.upload-file-google-drive' })}</Typography>
      </Box>
    </Box>
  );
};
