import { ConditionValueTypes } from './ConditionValuesTypes';
import { ConditionResources } from './ConditionResources';
import { ConditionEnumOption } from './SelectedCondition';

export enum ConditionValuePrefix {
  DATA = 'data.',
  DIFF = 'diff.',
}

interface Condition {
  name: string;
  type: ConditionValueTypes;
  value: string; // payload property to check condition by
  optional?: boolean; // used for externalEvent conditions
  multiSelection: boolean;
  alternativeValue?: string;
}

interface MultiSelectionConditionDefinition extends Condition {
  type: ConditionValueTypes.STRING | ConditionValueTypes.NUMBER;
  multiSelection: true;
}
interface BooleanConditionDefinition extends Condition {
  type: ConditionValueTypes.BOOLEAN;
  multiSelection: false;
}
interface ArrayConditionDefinition extends Condition {
  type: ConditionValueTypes.ARRAY;
  multiSelection: false;
}
interface ConditionBaseEnumString extends Condition {
  type: ConditionValueTypes.ENUM_STRING;
  enum: string[];
  multiSelection: false;
}

interface ConditionBaseEnumNumber extends Condition {
  type: ConditionValueTypes.ENUM_NUMBER;
  enum: number[];
  multiSelection: true;
}

interface ConditionBaseEnumOption extends Condition {
  type: ConditionValueTypes.ENUM_OPTION;
  enum: ConditionEnumOption[];
}

type ConditionBaseEnum = ConditionBaseEnumString | ConditionBaseEnumNumber | ConditionBaseEnumOption;

export interface ConditionBaseResource extends Condition {
  type: ConditionValueTypes.RESOURCE;
  resource: ConditionResources;
}

export interface ConditionChecklist extends Condition {
  type: ConditionValueTypes.CHECKLIST_ITEM;
}

export type ConditionDefinition =
  | MultiSelectionConditionDefinition
  | ArrayConditionDefinition
  | BooleanConditionDefinition
  | ConditionBaseEnum
  | ConditionBaseResource
  | ConditionChecklist;
