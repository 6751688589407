import React, { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Card, Stack, Typography } from '@mui/material';
import { NoteResponse } from '@workerbase/api/http/note';
import { getUser } from '@redux/Login';
import { WuiMediaDisplay, WuiUserAndDateLabel, WuiActionMenu, WuiActionMenuPosition } from '@uiKit';

export interface NoteCardProps {
  note: NoteResponse;
  onEdit: () => void;
  onDelete: () => void;
}

export const NoteCard: FC<NoteCardProps> = ({ note, onEdit, onDelete }) => {
  const {
    meta: { createdBy, updatedAt },
    text,
    media,
  } = note;
  const loggedInUser = useSelector(getUser);

  const canEdit = useMemo(() => loggedInUser?.id === createdBy._id, [loggedInUser]);

  if (!loggedInUser) {
    throw new Error('Not Connected');
  }
  const intl = useIntl();

  return (
    <Card variant="outlined" sx={{ padding: '16px' }}>
      <Stack direction="column" spacing="12px">
        <Stack direction="row" alignItems="center" justifyContent="stretch">
          <WuiUserAndDateLabel user={createdBy} date={updatedAt} />
          {canEdit && (
            <WuiActionMenu
              anchorOrigin={WuiActionMenuPosition.TopRight}
              popupOrigin={WuiActionMenuPosition.TopLeft}
              menuActions={[
                {
                  onClick: onEdit,
                  label: intl.formatMessage({ id: 'global.edit' }),
                },
                {
                  onClick: onDelete,
                  label: intl.formatMessage({ id: 'global.delete' }),
                },
              ]}
            />
          )}
        </Stack>
        {media && <WuiMediaDisplay id={media?._id} name={media?.fileName} type={media?.mimeType} />}
        {text && <Typography variant="body1">{text}</Typography>}
      </Stack>
    </Card>
  );
};
