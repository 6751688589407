import { AnyAction } from 'redux';

import { ListConfigFiltering, ListConfigPagination } from '@workerbase/api/http/user';
import { LocationsListConfig, LocationResponse } from '@workerbase/api/http/location';
import { PaginationMeta } from '@workerbase/types/Response';

import { LocationsActions } from './actions';
import { updatePagination, updateFiltering } from '../common/ListConfig/reducers';

export type LocationsState = Readonly<{
  locationsById: { [key: string]: LocationResponse };
  listConfigs: LocationsListConfig;
  currentListItemsIds: string[];
  errorMessage: string | null;
  importButtonDisabled: boolean;
}>;

export const initialState: LocationsState = {
  locationsById: {},
  listConfigs: {
    pagination: { currentPage: 1, itemsPerPage: 20 },
    filtering: {
      searchTerms: '',
    },
  },
  currentListItemsIds: [],
  errorMessage: null,
  importButtonDisabled: false,
};

interface GetLocationsSuccessActionPayload {
  locations: LocationResponse[];
  meta: PaginationMeta;
}

const reducer = (state: LocationsState = initialState, action: AnyAction): LocationsState => {
  switch (action.type) {
    case LocationsActions.GET_ALL_LOCATIONS_SUCCESS: {
      const payload = action.payload as GetLocationsSuccessActionPayload;

      const locationsById = payload.locations.reduce((prev, location) => {
        const updatedLocations = prev;
        updatedLocations[location._id] = location;
        return prev;
      }, {});

      return {
        ...state,
        locationsById: {
          ...state.locationsById,
          ...locationsById,
        },
        currentListItemsIds: payload.locations.map((location) => location._id),
      };
    }
    case LocationsActions.GET_LOCATIONS_SUCCESS: {
      const payload = action.payload as GetLocationsSuccessActionPayload;

      const locationsById = payload.locations.reduce((prev, location) => {
        const updatedLocations = prev;
        updatedLocations[location._id] = location;
        return prev;
      }, {});

      return {
        ...state,
        locationsById: {
          ...state.locationsById,
          ...locationsById,
        },
        listConfigs: {
          ...state.listConfigs,
          pagination: {
            ...state.listConfigs.pagination,
            currentPage: payload.meta.page,
            itemsPerPage: payload.meta.perpage,
            totalItems: payload.meta.totalItems,
          },
        },
        currentListItemsIds: payload.locations.map((location) => location._id),
      };
    }
    // GET_LOCATIONS_FAILURE is not handled here
    case LocationsActions.UPDATE_PAGINATION: {
      const payload = action.payload as ListConfigPagination;
      return updatePagination(state, payload);
    }
    case LocationsActions.UPDATE_FILTERING: {
      const payload = action.payload as ListConfigFiltering;
      return updateFiltering(state, payload);
    }
    default:
      return state;
  }
};

export default reducer;
