import { MediaMeta } from '@workerbase/api/http/media';
import { api } from './api';
import { MediaAsFilenameGET, MediaAsMimeTypeGET } from '../types/Media';

export const MEDIA_ENDPOINT = '/api/v1/media';

export const getMediaWithName = async (mediaName: string): Promise<Blob> => {
  const { data } = await api.get<Blob>(`${MEDIA_ENDPOINT}/${mediaName}`, { responseType: 'blob' });

  return data;
};

export const getMediaFileNameById = async (mediaId: string): Promise<MediaAsFilenameGET> => {
  const {
    data: { data },
  } = await api.get<{ data: MediaAsFilenameGET }>(`${MEDIA_ENDPOINT}/filename/${mediaId}`);

  return data;
};

export const getMediaMimeTypeById = async (mediaId: string): Promise<MediaAsMimeTypeGET> => {
  const {
    data: { data },
  } = await api.get<{ data: MediaAsMimeTypeGET }>(`${MEDIA_ENDPOINT}/mimetype/${mediaId}`);

  return data;
};

export const getMediaMetaById = async (mediaId: string): Promise<MediaMeta> => {
  const {
    data: { data },
  } = await api.get<{ data: MediaMeta }>(`${MEDIA_ENDPOINT}/${mediaId}/meta`);

  return data;
};

export const uploadMedia = async (file: File): Promise<string> => {
  const formData = new FormData();
  formData.append('file', file);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data } = await api.post<any>(MEDIA_ENDPOINT, formData);

  return data?.data?.createdMediaID;
};

export const uploadMediaFromGDrive = async (fileId: string, authToken: string): Promise<string> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data } = await api.post<any>(`${MEDIA_ENDPOINT}/gdrive`, { fileId, authToken });

  return data?.data?.createdMediaID;
};

export const deleteMedia = async (mediaId: string): Promise<string> => {
  const { data } = await api.delete(`${MEDIA_ENDPOINT}/${mediaId}`);

  return data;
};
