import React, { FC, useCallback, useEffect, useState } from 'react';
import { Stack, Typography, Box, SxProps, Theme } from '@mui/material';
import { useIntl } from 'react-intl';
import { WuiFileInput } from '@uiKit/WuiFileInput';
import { deleteInboxDocument, uploadInboxDocument } from 'services/networking/documents';
import { useMutation } from '@tanstack/react-query';
import { DocumentInboxTypes } from '@workerbase/domain/document';
import { ViewIdOptions } from './GoogleDrivePicker/GoogleDrivePicker';

interface UploadFilesProps {
  accept?: string[];
  sx?: SxProps<Theme>;
  onLoading?: (isLoading: boolean) => void;
  viewId?: ViewIdOptions;
}

export const UploadFiles: FC<UploadFilesProps> = ({
  sx,
  accept = ['image/*', 'video/*', 'audio/*'],
  onLoading,
  viewId = 'PDFS',
}) => {
  const intl = useIntl();
  const [counter, setCounter] = useState({ total: 0, successful: 0 });

  const { mutateAsync: createDocumentAsync, isPending: isPendingCreateDocument } = useMutation({
    mutationFn: uploadInboxDocument,
    retry: 2,
    retryDelay: 1000,
  });

  const { mutateAsync: deleteDocumentAsync, isPending: isPendingDeleteDocument } = useMutation({
    mutationFn: deleteInboxDocument,
    retry: 2,
    retryDelay: 1000,
  });

  useEffect(() => {
    onLoading?.(isPendingCreateDocument || isPendingDeleteDocument || counter.successful < counter.total);
  }, [counter.successful, counter.total, isPendingCreateDocument, isPendingDeleteDocument, onLoading]);

  const uploadedFilesCounter = `${counter.successful}/${counter.total}`;

  const handleCreateDocument = useCallback(
    async (mediaId: string) => {
      const document = await createDocumentAsync({
        mediaId,
        documentType: viewId === 'DOCS_VIDEOS' ? DocumentInboxTypes.Video : DocumentInboxTypes.File,
      });

      return document.data._id;
    },
    [createDocumentAsync, viewId],
  );

  return (
    <Stack component="section" px={4} pb={8} sx={sx}>
      <Typography mb={3} variant="h6">
        {intl.formatMessage({ id: 'documents.upload-files.title' })}{' '}
        <Box component="span" color="grey.500">
          {uploadedFilesCounter}
        </Box>
      </Typography>
      <WuiFileInput
        accept={accept ?? ['application/pdf']}
        multiple
        googleDriveUploadEnabled
        disabled={isPendingCreateDocument || isPendingDeleteDocument}
        onStatusChange={setCounter}
        onDelete={deleteDocumentAsync}
        onMediaUploadSuccessAdditionalRequest={handleCreateDocument}
        viewId={viewId}
      />
    </Stack>
  );
};
