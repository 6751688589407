import {
  BuilderStepUI,
  BuilderSectionUI,
  isBuilderSectionUI,
  StepListItem,
  DocumentVersionUpdateUI,
  DocumentVersionUI,
  BuilderStepInputChecklistUI,
} from 'components/DocumentBuilder/types';
import { isFilterCondition, isFilterGroup } from '@workerbase/domain/common';
import { getStepIdsUsedInDisplayCriteria, slugifySteps } from 'components/DocumentBuilder/utils';
import { DocumentVersionResponse, DocumentVersionUpdateBody } from '@workerbase/api/http/document';
import { VersionFormValues } from 'pages/Documents/DocumentPage/form/versionForm.types';

const replaceIdWithVariableNameInDisplayCriteria = (
  steps: (BuilderStepUI | BuilderSectionUI)[],
): (BuilderStepUI | BuilderSectionUI)[] => {
  const idToVariableNameMap = Object.entries(getVariableNameToIdMap(steps)).reduce<
    Record<string, { variableName: string; step: BuilderStepUI | BuilderSectionUI }>
  >((acc, [variableName, { id, step }]) => {
    acc[id] = { variableName, step };

    return acc;
  }, {});

  return steps.map((step) => {
    if (isBuilderSectionUI(step)) {
      const section = step;
      const newSection: BuilderSectionUI = {
        ...section,
        displayCriteria: section.displayCriteria
          ? {
              ...section.displayCriteria,
              conditions: section.displayCriteria.conditions.map((conditionGroup) => {
                if (isFilterGroup(conditionGroup)) {
                  return {
                    ...conditionGroup,
                    conditions: conditionGroup.conditions.map((condition) => {
                      if (isFilterCondition(condition)) {
                        const targetStep = idToVariableNameMap[condition.name].step;

                        if (!targetStep) {
                          console.error(`No target step found for condition ${condition.name}`);
                          return condition;
                        }

                        return {
                          ...condition,
                          ...(isBuilderStepInputChecklist(targetStep) && {
                            value: targetStep.options.find((option) => option._id === condition.value)?.value || '',
                          }),
                          name: idToVariableNameMap[condition.name].variableName,
                        };
                      }

                      return condition;
                    }),
                  };
                }

                return conditionGroup;
              }),
            }
          : null,
      };

      return newSection;
    }

    return step;
  });
};

/**
 * Returns a map of variable names to their corresponding step ids.
 * If a parent variable name is provided, the variable name will be prefixed with the parent variable name.
 */
const getVariableNameToIdMap = (
  steps: (BuilderStepUI | BuilderSectionUI)[],
  parentVariableName?: string,
): Record<string, { id: string; step: BuilderStepUI | BuilderSectionUI }> =>
  steps.reduce<Record<string, { id: string; step: BuilderStepUI | BuilderSectionUI }>>((acc, item) => {
    if (isBuilderSectionUI(item)) {
      return { ...acc, ...getVariableNameToIdMap(item.steps, item.variableName) };
    }

    const variableNameKey = parentVariableName ? `${parentVariableName}.${item.variableName}` : item.variableName;
    acc[variableNameKey] = { id: item._id, step: item };
    return acc;
  }, {});

export const replaceVariableNameWithIdInDisplayCriteria = (
  steps: (BuilderStepUI | BuilderSectionUI)[],
): (BuilderStepUI | BuilderSectionUI)[] => {
  const variableNameToIdMap = getVariableNameToIdMap(steps);

  return steps.map((step) => {
    if (isBuilderSectionUI(step)) {
      const section = step;
      const newSection: BuilderSectionUI = {
        ...section,
        displayCriteria: section.displayCriteria
          ? {
              ...section.displayCriteria,
              conditions: section.displayCriteria.conditions.map((conditionGroup) => {
                if (isFilterGroup(conditionGroup)) {
                  return {
                    ...conditionGroup,
                    conditions: conditionGroup.conditions.map((condition) => {
                      if (isFilterCondition(condition)) {
                        const targetStep: BuilderStepUI | BuilderSectionUI = variableNameToIdMap[condition.name]?.step;

                        if (!targetStep) {
                          console.error(`No target step found for condition ${condition.name}`);
                          return condition;
                        }

                        return {
                          ...condition,
                          ...(isBuilderStepInputChecklist(targetStep) && {
                            value: targetStep.options.find((option) => option.value === condition.value)?._id || '',
                          }),
                          name: variableNameToIdMap[condition.name].id,
                        };
                      }
                      return condition;
                    }),
                  };
                }
                return conditionGroup;
              }),
            }
          : null,
      };

      return newSection;
    }

    return step;
  });
};

const removeIdFromSteps = (steps: (BuilderStepUI | BuilderSectionUI)[]) =>
  steps.map((step) => {
    if (isBuilderSectionUI(step)) {
      const { _id, ...section } = step;
      const newSection: Omit<BuilderSectionUI, '_id'> = {
        ...section,
        steps: removeIdFromSteps(section.steps) as BuilderStepUI[],
      };

      return newSection;
    }

    const { _id, ...stepWithoutId } = step;

    if (isBuilderStepInputChecklist(step)) {
      return {
        ...stepWithoutId,
        options: step.options.map(({ _id: optionId, ...option }) => option),
      };
    }

    return stepWithoutId;
  });

export const normalizeVersionFromAPI = (version: DocumentVersionResponse): DocumentVersionUI => ({
  ...version,
  documentBuilder: version.documentBuilder
    ? {
        ...version.documentBuilder,
        _id: version.documentBuilder._id || '',
        steps: normalizeStepsFromAPI(version.documentBuilder.steps),
      }
    : undefined,
});

export const normalizeStepsFromAPI = (builderSteps: (BuilderStepUI | BuilderSectionUI)[] = []): StepListItem[] => {
  const stepUsedInSectionsDisplayCriteriaMap: Record<string, string[]> = {};
  const stepsWithIdsInDisplayCriteria = replaceVariableNameWithIdInDisplayCriteria(builderSteps);
  const stepsWithOptionValueIds = replaceTheOptionValueWithTheOptionValueId(stepsWithIdsInDisplayCriteria);
  const steps = stepsWithOptionValueIds;

  const stepListItems = steps.reduceRight<StepListItem[]>((acc, step) => {
    const stepListItem: StepListItem = {
      step: isBuilderSectionUI(step)
        ? {
            ...step,
            steps: step.steps.map((innerStep) => {
              const referencedInSteps = stepUsedInSectionsDisplayCriteriaMap[innerStep._id] || [];

              return {
                step: innerStep,
                meta: {
                  error: false,
                  referencedInSteps,
                  parentStepId: step._id,
                },
              };
            }),
          }
        : {
            ...step,
          },
      meta: {
        error: false,
        referencedInSteps: stepUsedInSectionsDisplayCriteriaMap[step._id] || [],
      },
    };

    if (isBuilderSectionUI(step)) {
      const displayCriteriaConditionStepIds = getStepIdsUsedInDisplayCriteria(step.displayCriteria);

      displayCriteriaConditionStepIds.forEach((conditionId) => {
        stepUsedInSectionsDisplayCriteriaMap[conditionId] = [
          ...(stepUsedInSectionsDisplayCriteriaMap[conditionId] || []),
          step._id,
        ];
      });
    }

    acc.unshift(stepListItem);

    return acc;
  }, []);

  return stepListItems;
};

export const normalizeVersionForAPI = (document: DocumentVersionUpdateUI): DocumentVersionUpdateBody => {
  const normalizeSteps = (steps?: StepListItem[]): DocumentVersionUpdateBody['steps'] => {
    /**
     * We don't check on empty array here as it needs to be sent as an empty array
     * in the case where the user removes all steps.
     */
    if (!steps) {
      return undefined;
    }

    const removeMetaFromSteps = steps.map(stepListItemToBuilderStep);
    const reSlugifySteps = slugifySteps(removeMetaFromSteps);
    const replaceIdWithVariableNameInDisplayCriteriaSteps = replaceIdWithVariableNameInDisplayCriteria(reSlugifySteps);
    const replaceOptionIdsWithValues = replaceTheOptionValueIdWithTheValueOption(
      replaceIdWithVariableNameInDisplayCriteriaSteps,
    );
    const removeIdFromStepsSteps = removeIdFromSteps(replaceOptionIdsWithValues);

    return removeIdFromStepsSteps;
  };

  return {
    ...document,
    steps: normalizeSteps(document.steps),
  };
};

const stepListItemToBuilderStep = ({ step }: StepListItem): BuilderStepUI | BuilderSectionUI => {
  if (isBuilderSectionUI(step)) {
    return {
      ...step,
      steps: step.steps.map(({ step }) => step),
    };
  }
  return step as BuilderStepUI;
};

const isBuilderStepInputChecklist = (step: BuilderStepUI | BuilderSectionUI): step is BuilderStepInputChecklistUI =>
  !isBuilderSectionUI(step) && 'options' in step && 'flaggingCriteria' in step;

const replaceTheOptionValueIdWithTheValueOption = (
  steps: (BuilderStepUI | BuilderSectionUI)[],
): (BuilderStepUI | BuilderSectionUI)[] =>
  steps.map((step) => {
    if (isBuilderSectionUI(step)) {
      return {
        ...step,
        steps: replaceTheOptionValueIdWithTheValueOption(step.steps),
      } as BuilderSectionUI;
    }

    if (isBuilderStepInputChecklist(step) && step.flaggingCriteria) {
      const optionIdToValueMap = step.options.reduce<Record<string, string>>((acc, option) => {
        if (option._id && option.value) {
          acc[option._id] = option.value;
        }
        return acc;
      }, {});

      return {
        ...step,
        flaggingCriteria: {
          ...step.flaggingCriteria,
          conditions: step.flaggingCriteria.conditions.map((conditionGroup) => {
            if (isFilterGroup(conditionGroup)) {
              return {
                ...conditionGroup,
                conditions: conditionGroup.conditions.map((condition) => {
                  if (isFilterCondition(condition)) {
                    return {
                      ...condition,
                      name: optionIdToValueMap[condition.name],
                    };
                  }
                  return condition;
                }),
              };
            }
            return conditionGroup;
          }),
        },
      };
    }

    return step;
  });

const replaceTheOptionValueWithTheOptionValueId = (
  steps: (BuilderStepUI | BuilderSectionUI)[],
): (BuilderStepUI | BuilderSectionUI)[] =>
  steps.map((step) => {
    if (isBuilderSectionUI(step)) {
      return {
        ...step,
        steps: replaceTheOptionValueWithTheOptionValueId(step.steps),
      } as BuilderSectionUI;
    }

    if (isBuilderStepInputChecklist(step) && step.flaggingCriteria) {
      const optionValueToIdMap = step.options.reduce<Record<string, string>>((acc, option) => {
        if (option._id && option.value) {
          acc[option.value] = option._id;
        }
        return acc;
      }, {});

      return {
        ...step,
        flaggingCriteria: {
          ...step.flaggingCriteria,
          conditions: step.flaggingCriteria.conditions.map((conditionGroup) => {
            if (isFilterGroup(conditionGroup)) {
              return {
                ...conditionGroup,
                conditions: conditionGroup.conditions.map((condition) => {
                  if (isFilterCondition(condition)) {
                    return {
                      ...condition,
                      name: optionValueToIdMap[condition.name],
                    };
                  }
                  return condition;
                }),
              };
            }
            return conditionGroup;
          }),
        },
      };
    }

    return step;
  });

export const normalizeFormValuesForAPI = (
  formData: VersionFormValues,
  version: Partial<DocumentVersionUI>,
): Partial<DocumentVersionUI> => ({
  ...(formData.requireSignature !== undefined && { requireSignature: formData.requireSignature }),
  ...(formData.safetySymbols && {
    safetySymbols: {
      mandatory: formData.safetySymbols.mandatory?.map((option) => option.value) ?? [],
    },
  }),
  ...(version.viewData && {
    viewData: {
      ...version.viewData,
      original: {
        ...version.viewData?.original,
        description: formData.viewData?.original.description ?? '',
      },
    },
  }),
});
