import { IPaginatedQueryParams, ApiResponsePaginated } from '@workerbase/api/http/common';
import { ListConfigSorting } from '@workerbase/api/http/user';
import { normalizeFunction, normalizeFunctionLog } from 'services/normalizers/functions';
import {
  FunctionResponse,
  FunctionLog,
  FunctionLogResponse,
  FunctionCreateBody,
  FunctionUpdateBody,
  FunctionType,
  GetFunctionsParams,
} from '@workerbase/api/http/function';
import { api } from './api';

export const FUNCTIONS_ENDPOINT = '/api/v1/functions';

type GetFunctions = (options: {
  projectId?: string;
  page?: number;
  perPage?: number;
  sorting?: ListConfigSorting;
  filtering?: string;
  aggregateLinks?: boolean;
}) => Promise<ApiResponsePaginated<FunctionType[]>>;

export const getFunctions: GetFunctions = async ({
  projectId,
  page = 1,
  perPage = 20,
  sorting,
  filtering,
  aggregateLinks,
}) => {
  const params: GetFunctionsParams = {
    page,
    perpage: perPage,
    sort: sorting?.selector,
    order: sorting?.sortDirection,
    textSearch: filtering,
    project: projectId,
    aggregateLinks,
  };

  const {
    data: { data, ...rest },
  } = await api.get<ApiResponsePaginated<FunctionResponse[]>>(FUNCTIONS_ENDPOINT, { params });

  return { data: data.map(normalizeFunction), ...rest };
};

export const getFunctionById = async (functionId: string): Promise<FunctionType> => {
  const {
    data: { data },
  } = await api.get<{ data: FunctionResponse }>(`${FUNCTIONS_ENDPOINT}/${functionId}`);

  return normalizeFunction(data);
};

export const createFunction = async (func: FunctionCreateBody): Promise<FunctionType> => {
  const {
    data: { data },
  } = await api.post<{ data: FunctionResponse }>(FUNCTIONS_ENDPOINT, func);

  return normalizeFunction(data);
};

export const deleteFunctionById = async (functionId: string): Promise<boolean> => {
  await api.delete<{ success: boolean }>(`${FUNCTIONS_ENDPOINT}/${functionId}`);

  return true;
};

export const updateFunctionById = async (functionId: string, func: FunctionUpdateBody): Promise<FunctionType> => {
  const {
    data: { data },
  } = await api.put<{ data: FunctionResponse }>(`${FUNCTIONS_ENDPOINT}/${functionId}`, func);

  return normalizeFunction(data);
};

export const deployFunctionWithId = async (functionId: string): Promise<boolean> => {
  await api.post<unknown>(`${FUNCTIONS_ENDPOINT}/${functionId}/deploy`);

  return true;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const invokeFunctionWithId = async (functionId: string, payload: Record<string, any>): Promise<boolean> => {
  await api.post<unknown>(`${FUNCTIONS_ENDPOINT}/${functionId}/invoke`, payload);

  return true;
};

type GetFunctionLogs = (
  functionId: string,
  page: number,
  perPage: number,
  sorting?: ListConfigSorting,
  filtering?: string,
) => Promise<ApiResponsePaginated<FunctionLog[]>>;

export const getFunctionLogs: GetFunctionLogs = async (functionId, page = 1, perPage = 20, sorting, filtering) => {
  const params: IPaginatedQueryParams = {
    page,
    perpage: perPage,
    sort: sorting?.selector,
    order: sorting?.sortDirection,
    textSearch: filtering,
  };

  const {
    data: { data, ...rest },
  } = await api.get<ApiResponsePaginated<FunctionLogResponse[]>>(`${FUNCTIONS_ENDPOINT}/${functionId}/history`, {
    params,
  });

  return { data: data.map(normalizeFunctionLog), ...rest };
};
