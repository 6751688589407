import { LocationStructureItemPOST } from 'services/types/Location';
import { LocationResponse, LocationBody } from '@workerbase/api/http/location';
import { PaginationMeta } from '@workerbase/types/Response';

import { BaseAction } from '../common/actions';

import { updatePaginationFactory, updateFilteringFactory } from '../common/ListConfig/actions';

export enum LocationsActions {
  GET_ALL_LOCATIONS_REQUEST = '@@locations/GET_ALL_LOCATIONS_REQUEST',
  GET_ALL_LOCATIONS_SUCCESS = '@@locations/GET_All_LOCATIONS_SUCCESS',
  GET_LOCATIONS_REQUEST = '@@locations/GET_LOCATIONS_REQUEST',
  GET_LOCATIONS_SUCCESS = '@@locations/GET_LOCATIONS_SUCCESS',
  GET_LOCATIONS_FAILURE = '@@locations/GET_LOCATIONS_FAILURE',
  DELETE_LOCATION_BY_ID_REQUEST = '@@locations/DELETE_LOCATION_BY_ID_REQUEST',
  DELETE_LOCATION_BY_ID_SUCCESS = '@@locations/DELETE_LOCATION_BY_ID_SUCCESS',
  DELETE_LOCATION_BY_ID_FAILURE = '@@locations/DELETE_LOCATION_BY_ID_FAILURE',
  UPDATE_LOCATION_BY_ID_REQUEST = '@@locations/UPDATE_LOCATION_BY_ID_REQUEST',
  CREATE_LOCATION_REQUEST = '@@locations/CREATE_LOCATION_REQUEST',
  UPDATE_LOCATION_STRUCTURE_REQUEST = '@@locations/UPDATE_LOCATION_STRUCTURE_REQUEST',
  UPDATE_PAGINATION = '@@locations/UPDATE_PAGINATION',
  UPDATE_FILTERING = '@@locations/UPDATE_FILTERING',
}

/**
 * TODO: needs to be refactored during moving listConfig to the ContextAPI https://workerbase.atlassian.net/browse/WB-4665
 * @deprecated
 */
export const getAllLocationsRequestAction = (): BaseAction => ({
  type: LocationsActions.GET_ALL_LOCATIONS_REQUEST,
  payload: {},
});

export const getAllLocationsSuccess = (locations: LocationResponse[]): BaseAction => ({
  type: LocationsActions.GET_ALL_LOCATIONS_SUCCESS,
  payload: {
    locations,
  },
});

export const getLocationsRequestAction = (): BaseAction => ({
  type: LocationsActions.GET_LOCATIONS_REQUEST,
  payload: {},
});

export const getLocationsSuccess = (locations: LocationResponse[], meta: PaginationMeta): BaseAction => ({
  type: LocationsActions.GET_LOCATIONS_SUCCESS,
  payload: {
    locations,
    meta,
  },
});

export const getLocationsError = (errorMessage: string): BaseAction => ({
  type: LocationsActions.GET_LOCATIONS_FAILURE,
  payload: {
    errorMessage,
  },
});

// CREATE_LOCATION
export interface CreateLocationRequestAction {
  type: string;
  payload: {
    location: LocationBody;
  };
}
export const createLocationRequest = (location: LocationBody): CreateLocationRequestAction => ({
  type: LocationsActions.CREATE_LOCATION_REQUEST,
  payload: { location },
});

// DELETE_LOCATION_BY_ID
export interface DeleteLocationByIdRequestAction {
  type: string;
  payload: {
    locationId: string;
  };
}
export const deleteLocationByIdRequest = (locationId: string): DeleteLocationByIdRequestAction => ({
  type: LocationsActions.DELETE_LOCATION_BY_ID_REQUEST,
  payload: { locationId },
});

export const deleteLocationByIdSuccess = (locationId: string): DeleteLocationByIdRequestAction => ({
  type: LocationsActions.DELETE_LOCATION_BY_ID_SUCCESS,
  payload: {
    locationId,
  },
});

export const deleteLocationByIdError = (errorMessage: string): BaseAction => ({
  type: LocationsActions.DELETE_LOCATION_BY_ID_FAILURE,
  payload: {
    errorMessage,
  },
});

// UPDATE_LOCATION_BY_ID
export interface UpdateLocationByIdRequestAction {
  type: string;
  payload: {
    location: LocationBody;
    locationId: string;
  };
}

export const updateLocationByIdRequest = (
  locationId: string,
  location: LocationBody,
): UpdateLocationByIdRequestAction => ({
  type: LocationsActions.UPDATE_LOCATION_BY_ID_REQUEST,
  payload: { location, locationId },
});

export interface UpdateLocationStructureRequestAction {
  type: string;
  payload: {
    locationItems: LocationStructureItemPOST[];
  };
}

export const updateLocationStructureRequest = (
  locationItems: LocationStructureItemPOST[],
): UpdateLocationStructureRequestAction => ({
  type: LocationsActions.UPDATE_LOCATION_STRUCTURE_REQUEST,
  payload: { locationItems },
});

// PAGINATION
export const updatePagination = updatePaginationFactory(LocationsActions.UPDATE_PAGINATION);
export const updateFiltering = updateFilteringFactory(LocationsActions.UPDATE_FILTERING);
