import { call, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import {
  getLocations,
  createLocation,
  deleteLocationById,
  updateLocationById,
  updateLocationStructure,
} from 'services/networking/locations';
import { LocationResponse } from '@workerbase/api/http/location';
import { PaginationMeta } from '@workerbase/types/Response';

import { handleRequestError } from '../common';

import { getListConfigs } from './selectors';

import {
  getAllLocationsSuccess,
  getLocationsSuccess,
  getLocationsError,
  LocationsActions,
  CreateLocationRequestAction,
  deleteLocationByIdSuccess,
  deleteLocationByIdError,
  UpdateLocationByIdRequestAction,
  UpdateLocationStructureRequestAction,
  getLocationsRequestAction,
} from './actions';
import { Routes } from '../../routes';

export function* getAllLocationsRequestSaga(action) {
  try {
    const listConfigs = {
      pagination: {
        currentPage: 1,
        itemsPerPage: -1,
      },
    };

    const response = yield call(getLocations, listConfigs.pagination.currentPage, listConfigs.pagination.itemsPerPage);
    const locations: LocationResponse[] = response.data;
    yield put(getAllLocationsSuccess(locations));
  } catch (error) {
    yield put(getLocationsError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* getLocationsRequestSaga(action) {
  try {
    const listConfigs = yield select(getListConfigs);

    const response = yield call(
      getLocations,
      listConfigs.pagination.currentPage,
      listConfigs.pagination.itemsPerPage,
      listConfigs.sorting,
      listConfigs.filtering.searchTerms,
    );

    const locations: LocationResponse[] = response.data;
    const meta: PaginationMeta = response.meta;

    yield put(getLocationsSuccess(locations, meta));
  } catch (error) {
    yield put(getLocationsError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* createLocationRequestSaga(action: CreateLocationRequestAction) {
  try {
    yield call(createLocation, action.payload.location);
    yield put(push(Routes.Locations));
    yield call(toast.success, 'Location created');
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* updateLocationByIdRequestSaga(action: UpdateLocationByIdRequestAction) {
  try {
    yield call(updateLocationById, action.payload.locationId, action.payload.location);
    yield put(push(Routes.Locations));
    yield call(toast.success, 'Location updated');
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* deleteLocationByIdRequestSaga(action) {
  try {
    yield call(deleteLocationById, action.payload.locationId);

    yield call(toast.success, 'Location deleted');
    yield put(deleteLocationByIdSuccess(action.payload.locationId));
    yield put(getLocationsRequestAction());
  } catch (error) {
    yield put(deleteLocationByIdError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* updateLocationStructureRequestSaga(action: UpdateLocationStructureRequestAction) {
  try {
    yield call(updateLocationStructure, action.payload.locationItems);

    yield call(toast.success, 'Location structure updated');
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export default function* locationsSagas() {
  yield takeLatest(LocationsActions.GET_ALL_LOCATIONS_REQUEST, getAllLocationsRequestSaga);
  yield takeLatest(LocationsActions.GET_LOCATIONS_REQUEST, getLocationsRequestSaga);
  yield takeLatest(LocationsActions.CREATE_LOCATION_REQUEST, createLocationRequestSaga);
  yield takeLatest(LocationsActions.DELETE_LOCATION_BY_ID_REQUEST, deleteLocationByIdRequestSaga);
  yield takeLatest(LocationsActions.UPDATE_LOCATION_BY_ID_REQUEST, updateLocationByIdRequestSaga);
  yield takeLatest(LocationsActions.UPDATE_LOCATION_STRUCTURE_REQUEST, updateLocationStructureRequestSaga);
}
