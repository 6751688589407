import { ComparingConditionOperator, ConditionOperator, DirectConditionOperator } from '../RuleConditionOperator';

export interface SelectedCondition {
  name: string;
  value?: string;
  operator: ConditionOperator;
}
export interface DirectSelectedCondition extends SelectedCondition {
  name: string;
  operator: DirectConditionOperator;
  value: undefined;
}

export interface ComparingSelectedCondition extends SelectedCondition {
  name: string;
  operator: ComparingConditionOperator;
  value: string;
}

export interface ConditionEnumOption {
  label: string;
  value: string;
}

export const isComparingCondition = (condition: SelectedCondition): condition is ComparingSelectedCondition =>
  'value' in condition;
