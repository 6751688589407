import _ from 'lodash';
import { FunctionType } from '@workerbase/api/http/function';
import { RootState } from '../types';

export const getFunctionsOnCurrentPage = (store: RootState): FunctionType[] =>
  store.functions.currentListItemsIds.map((id) => store.functions.functionsById[id]);

export const getListConfigs = (store: RootState) => store.functions.listConfigs;

export const getFunctionWithId =
  (functionId?: string) =>
  (store: RootState): FunctionType | undefined =>
    functionId ? store.functions.functionsById[functionId] : undefined;

export const isFunctionDeployed =
  (functionId?: string) =>
  (store: RootState): boolean =>
    functionId ? _.get(store, `functions.functionsById.${functionId}.deployed`, false) : false;

export const getIsDeploying = (store: RootState) => store.functions.isDeploying;
