import { ResourceTypes } from './ResourceTypes.enum';

export type ListableResourceTypes = Extract<
  ResourceTypes,
  | ResourceTypes.Database
  | ResourceTypes.Project
  | ResourceTypes.Rule
  | ResourceTypes.Location
  | ResourceTypes.Media
  | ResourceTypes.Role
  | ResourceTypes.Skill
  | ResourceTypes.Workinstruction
  | ResourceTypes.User
  | ResourceTypes.Function
  | ResourceTypes.Asset
  | ResourceTypes.AssetType
  | ResourceTypes.Machine
  | ResourceTypes.MachineType
  | ResourceTypes.ProductionLine
  | ResourceTypes.ProductType
  | ResourceTypes.MaterialType
  | ResourceTypes.WorkOrder
  | ResourceTypes.Workstation
  | ResourceTypes.Document
>;

export const ListableResourceTypes = Object.freeze({
  Database: ResourceTypes.Database,
  Project: ResourceTypes.Project,
  Rule: ResourceTypes.Rule,
  Location: ResourceTypes.Location,
  Media: ResourceTypes.Media,
  Role: ResourceTypes.Role,
  Skill: ResourceTypes.Skill,
  Workinstruction: ResourceTypes.Workinstruction,
  User: ResourceTypes.User,
  Function: ResourceTypes.Function,
  Asset: ResourceTypes.Asset,
  AssetType: ResourceTypes.AssetType,
  Machine: ResourceTypes.Machine,
  MachineType: ResourceTypes.MachineType,
  ProductionLine: ResourceTypes.ProductionLine,
  ProductType: ResourceTypes.ProductType,
  MaterialType: ResourceTypes.MaterialType,
  WorkOrder: ResourceTypes.WorkOrder,
  Workstation: ResourceTypes.Workstation,
  Document: ResourceTypes.Document,
});
